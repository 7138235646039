export default {
  "login": {
    "sign": "Log in",
    "login": "Log in immediately",
    "remember": "Remember Me",
    "about": "About Us",
    "illegal_email": "Invalid email format",
    "illegal_password": "Your password requires 6-16 digits, and must contain letters and numbers.",
    "password_matching": "The two passwords are inconsistent",
    "enterEmail": "please Enter Email or Jackery ID",
    "enter_code": "Please Enter Code",
    "forgot": "Forgotten your password?",
    "create": "Create an account",
    "thirdLogin": "Or log in with",
    "accountActivate": "Account activated",
    "bindEmail": "Bind your email",
    "error": "Login failed, please try again later",
    "quickLogin": "Password-free log in",
    "quickLoginA": "Password-free log in"
  },
  "policies": {
    "content": "By logging in with a third party, it means you have read and agreed with our",
    "policy-link": "https://www.jackery.com/policies/privacy-policy",
    "policy": "Privacy Policy",
    "and": "and",
    "terms-link": "https://www.jackery.com/policies/terms-of-service",
    "terms": "Terms of Service",
    "comma": ".",
    "footer": "Jackery will use information provided by the third party you chose to register or log in with."
  },
  "reset": {
    "modify": "Change your password",
    "back": "Back to log in ",
    "success": "New password created successfully!",
    "referrer": "Go back to the original page in",
    "seconds": "seconds"
  },
  "register": {
    "title": "Register",
    "enterEmail": "please Enter Email",
    "agree": "I agree to Jackery's ",
    "agree_after": "",
    "getDeals": "I agree to receive Jackery's latest news, promotion and product information.",
    "login": "Log In Now",
    "success": "Your account '{email}' has been registered successfully!",
    "welcomeEmail": "A welcome message has been sent to your email address."
  },
  "header": {
    "logout": 'Log out',
    "userCenter": "Account Center",
    "personalCenter": "Personal Center",
    "shopping": "Go Shopping",
    "setting": "Setting",
    "myJackery": "Account Center",
    "jackeryID": "Personal Center",
    "wishlist": "My Wishlist",
  },
  "footer": {
    "cookieContent": "We use cookies including third-party cookies to ensure the website runs smoothly, analyze the traffic, provide a improved social media experience and personalize content and ads.",
    "cookieUrl": "#",
    "learn": "Learn More",
    "accept": "Accept Cookies",
    "copyright": "Copyright © {date} Jackery UK Ltd. All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "Sitemap"
  },
  "account": {
    "welcome": "Welcome",
    "email": "Email",
    "registerEmail": "Register Email",
    "bindNewRegisterEmail": "New Email Address",
    "emailIsEqual": "The new email address is the same as the original one, no need to modify.",
    "settings": "Account Settings",
    "settingTips": "Quickly manage your account information and security details.",
    "titleProfiles": "My Profile",
    "titleSecurity": "Account Security",
    "titleAuthorization": "Bind your account",
    "nickname": "Nickname",
    "name": "Full Name",
    "gender": "Gender",
    "birthday": "Date of Birth",
    "contactEmail": "Email Address",
    "enterprise": "Enterprise",
    "setted": "Set",
    "unset": "Unset",
    "password": "Password",
    "pop_password": "Create Password",
    "apply": "Apply",
    "secrecy": "Secrecy",
    "male": "Male",
    "female": "Female",
    "currentPassword": "Current Password",
    "newPassword": "New Password",
    "newShowAgain": "Never show again",
    "againPassword": "Confirm new password",
    "jackeryIdTips": "The Jackery ID can be edited only once, The Jackery ID must contain at least 6 characters, and can include numbers and letters, but can not exceed 32 characters.",
    "birthdayTips": "Please input your date of birth for us to provide the highest quality service. We will keep all your personal information strictly confidential.",
    "nickNameTips": "The nickname can consist of any letters or numbers, and can not exceed 32 characters.",
    "nameTips": "It is strongly suggested for you to use your real name, for us to contact you. We will keep all personal information strictly confidential.",
    "genderTips": "Please input your gender for us to provide a high quality service. We'll keep all personal information strictly confidential.",
    "contactEmailTips": "Please use the email address that you use most frequently, so we can contact you conveniently. We will keep your personal information strictly confidential.",
    "phoneNumberTips": "Please input the contact number you use most frequently, so we can contact you conveniently. We will keep your personal information strictly confidential.",
    "phoneNumberTips2": "Only numbers, brackets and hyphens are supported.",
    "enterpriseNameTips": "If you are using a business account, please tell us your relevant business information, for us to provide the highest quality service.",
    "emailTips": "The email address you registered with is your login account. To secure your user account, security verification is required to change the registered email address.",
    "passwordTips": "For security reasons, please set a stronger password.",
    "location": "Location",
    "contactPhone": "Phone",
    "avatarSettings": "Avatar Settings",
    "choosePicture": "Select Picture",
    "myProfile": "My profile",
    "changePassword": "Change Password",
    "creatPassword": "Creat Password",
    "firstName": "First Name",
    "lastName": "Last Name",
    "editPasswordTips": "Your password needs 6-16 digits, and must include letters and numbers. For security reasons, please set a strong password.",
    "subscriberTip": "Get { num } Points for my first email subscribtion and Receive Jackery‘s latest news and offers. Agree to Jackery's Terms of Service and Privacy Policy.",
    "birthdayTip1": "Get {num} Points by Filling in your birthday.",
    "birthdayTip2": "Earn {num} points on each birthday and earn double shopping points.",
    "birthdayTip3": "Only one chance to amend.",
    "phoneTip": "Get {num} Points for my first SMS subscribtion and Receive Jackery‘s latest news and offers.Agree to Jackery's Terms of Service and Privacy Policy.",
    "countryRegion": "Country/Region",
    "successTip1": "You have submit successfully, {num} Jackery points will be earned within 24 hours.",
    "successTip": "You have submitted your profile successfully.",
    "errorTip": "Submission failed, please check and submit again."
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "Home",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Register",
    "login": "Login",
    "reset": "Reset",
    "dashboard": "Dashboard",
    "commonSetting": "General Settings",
    "account": "Account Setting",
    "accountOverview": "Overview",
    "myProfile": "My Profile",
    "myOrders": "My Order",
    "personalSettings": "Personal Settings",
    "addressBook": "My Address",
    "accountSettings": "Account Settings",
    "myDevices": "My Device",
    "activate": "Activate the account",
    "myWallet": "My Wallet",
    "myService": "My Service",
    "myReturn": "My Return",
    "myJackeryLife": "My Jackery Stories",
    "myWishlist": "My Wishlist",
    "myTradeIn": "My Upgrade",
    "myReferral": "My Referral",
  },
  "description": {
    "memberIndex": "Jackery Membership",
    "memberGuide": "Jackery Membership Guide",
    "home": "Jackery Membership, Jackery Account Center",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Create a Jackery ID, to become a Jackery member.",
    "login": "Log in as a customer",
    "reset": "Reset",
    "dashboard": "Dashboard",
    "commonSetting": "General Settings",
    "account": "Jackery ID Account Center",
    "accountOverview": "Overview",
    "myProfile": "My Profile",
    "myOrders": "My Order",
    "personalSettings": "Personal Settings",
    "addressBook": "Address Book",
    "accountSettings": "Account Settings",
    "myDevices": "Account Devices",
    "activate": "Create your password to activate your account",
    "registerActivate": "Please click below to activate your account. Once activated, you can use the account to log into our website.",
    "myReturn": "My Return",
    "myWallet": "My Wallet",
    "myService": "My Service",
    "myJackeryLife": "My Jackery Life",
    "myTradeIn": "My Upgrade"
  },
  "operate": {
    "enable": "Enable",
    "disable": "Disable",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "remove": "Remove",
    "search": "Search",
    "reset": "Reset",
    "add": "Add",
    "close": "Close",
    "edit": "Edit",
    "yes": "Yes",
    "no": "No",
    "submit": "Submit",
    "next": "Next",
    "prev": "Prev",
    "back": "Back",
    "reject": "Reject",
    "accept": "Accept"
  },
  "tips": {
    "sure": "Are You Sure?",
    "placeholder": "Please enter",
    "enterConsultContent": "Please enter your inquiry content",
    "all": "All",
    "custom": "Custom",
    "pending": "Pending",
    "loading": "Processing...",
    "loading2": "Processing",
    "cancelled": "Cancelled",
    "loaded": "Process completed",
    "loaded2": "Completed",
    "changeShop": "Switching the store",
    "logoutConfirm": "Are you sure to log out?",
    "loggedOut": "Log out successfully",
    "refreshed": "Refreshed",
    "noDescribe": "No description",
    "reminder": "Warm tips",
    "commError": "Something with wrong",
    "reLogin": "Please login again",
    "verify": "Verify Code", "sendVerify": "The verification code has been sent, please check your email inbox or junk email.",
    "getVerifyCode": "Obtain your verification code",
    "verifyError": "Incorrect Verification Code",
    "homePage": "Homepage",
    "404": "This page doesn't exist.",
    "404Tips": "Please check if server IP address is correct, or click to continue to visit the link.",
    "500": "Oops, something went wrong.",
    "500Tips": "Server error,",
    "500Tips2": "Please try again later.",
    "urlExpired": "Verification URL has expired",
    "accountNotActivated": "Account has not been activated",
    "accountActivatedSuccess": "Your account has been activated successfully.",
    "requestFrequently": "Please do not act frequently, retry it once verified.",
    "submitSuccess": "Successfully submit it.",
    "zipVerifyError": "Postal code verification failed",
    "otherError": "Something with wrong, please contact administrator. code: {code}",
    "wrongPasswordMultipleTimes": "You have entered too many wrong passwords. Please try again {minute}minutes later.",
    "networkError": "Network Error",
    "thirdLoginError": "Login failed, please try again with your account.",
    "fileTypeError": "File type error, only upload is allowed: {type} type file",
    "copySuccessfully": "Copy Successfully"
  },
  "placeholder": {
    "email": "Email Address *",
    "emailOrJackeryID": "Email address/Jackery ID *",
    "password": "Password *",
    "passwordAgain": "Confirm new password *",
    "enterprise": "Enterprise Name",
    "code": "Code *",
    "firstName": "First Name",
    "lastName": "Last Name",
    "select": "Please select",
    "address1": "Street and phone number",
    "address2": "Apartment, suite, etc.(optional)",
    "country": "country/region",
    "province": "state/province/region",
    "city": "Your city ",
    "region": "Region",
    "zip": "ZIP Code",
    "zipError": "Incorrect ZIP",
    "phone": "Your phone ",
    "company": "Company Name (optional)",
    "isNotEmpty": "This option can not be empty",
    "pobox": "We do not deliver to P.O. Boxes",
    "minChar2": "Minimum 2 characters",
    "inputChar": "Character length is between {length} digits"
  },
  "order": {
    "sn": "Order Number",
    "detail": "View Shipping Details",
    "productName": "Product Name",
    "quantity": "Quantity",
    "price": "Price ",
    "status": "Order Status",
    "consult": "Consult",
    "contact": "Contact Us",
    "total": "In Total",
    "empty": "No order information ",
    "questionType": "Issue Type",
    "shipped": "Shipped",
    "return": "Return",
    "applied": "Returned and refunded",
    "revoke": "Revoke",
    "serviceType": "Please select service type",
    "serviceType_1": "Return and Refund",
    "serviceType_2": "Exchanges and Replacements",
    "packageArrived": "Did you received the items",
    "reason": "Please enter a reason",
    "reason_1": "{day}-day No Questions Asked Return",
    "reason_2": "Quality issues",
    "reason_3": "Other",
    "quality": "Please enter the specific quality problem",
    "productSerialNumber": "Please enter the product serial number",
    "selectReturnProduct": "Please select a return product",
    "returnQuantity": "Please enter the number of returns/replacements",
    "shippingAddress": "Shipping address",
    "returnAddress": "Return address",
    "revise": "Edit",
    "uploadVideo": "Upload video/image",
    "uploadLoading": "Uploading",
    "videoLimit": "The file size can not exceed {limit}M!",
    "uploadVideoError": "File upload failed",
    "failureDetermination": "Product quality troubleshooting",
    "steps_1": "1. Power station troubleshooting steps",
    "steps_1_content": "1) Please press the display to see if the indicator light is on.\n2)  Please reset the power station and place it somewhere for a while before recharging the device again.\n ---Hold press 'DISPLAY' button for 10S if the power station is E160/E240/E500/E1000\n ---hold press 'DISPLAY+USB' for 10S if the power station is E1500/E2000 \n3) Press the button on the top of the AC/DC outlet to check if the light is on before you plug in your device and have it charged.\n4) Please check if the device can be charged normally again.\n\nThe power station is defective if the above steps are not working.\n\n",
    "steps_2": "2. Solar panel troubleshooting steps",
    "steps_2_content": "1) Please use the provided AC charger to charge the power station to see if it can work.\n2) Please test under midday strong sunlight. Connect the solar panel with the power station, make sure there is no shadow cast on the panel.\n\nIt is defective if the power station can be recharged well by AC charger but not working with the solar panel.",
    "learnMore": "More",
    "shrink": "Shrink",
    "noReason_30_day": "1. Jackery offers a 30-day money back guarantee for goods purchased directly from Jackery.com. We are confident that you will love your new Jackery product. However, if you are unsatisfied for any reason, simply request a return for a replacement or refund within 30 days. \n\n2. Please be noted: returns without reason exceeding 30 days will not be accepted.\n\n3. In order to return your product under the 30-day return policy, please check that: Make sure the product is returned in the original packaging, with all materials included, in new or as-new condition.",
    "selectDelivery": "Please select return logistics",
    "enterTrackNumber": "Please enter tracking number",
    "illegal_trackNumber": "Please enter the correct tracking number",
    "enterDescription": "Please enter a specific description",
    "comingSoon": 'Coming Soon',
    "cancelled": 'Cancelled',
    "points": "points",
    "comingSoonTip": '（Jackery Points will be issued on the 31st day after the product is shipped）',
    "pointsBalance": "Points Balance",
    "tipTitle": 'Member Benefits',
    "shopNow": "Shop Now",
    "tip1": `① By <a> Making Orders </a> on Jackery official website, you'll enjoy automatic extended warranty (up to 5 years) and get Jackery Points (1 dollar spent = 1 point earned).`,
    "tip2": `② By <a> Registering Products </a> you have purchased from unofficial channels, you will extend your warranty (up to 5 years).`,
    "tip3": `③ By <a> Placing an order over {num} </a> on Jackery official website after registration, you can get a free gift for once.`
  },
  "bank": {
    "name": "Bank name",
    "branchName": "branch name",
    "type": "Bank card type",
    "account": "Bank card number",
    "realName": "Cardholder's name"
  },
  "return": {
    "orderNumber": "Order number",
    "status": "Return/replacement status",
    "attachment": "Attachment",
    "confirmRevoke": "Do you want to cancel the return/replacement application?",
    "returnPolicy_link": "https://www.jackery.com/pages/policy",
    "returnPolicy_1": "For FAQs related to the application of repair, returns, replacements, and exchanges, please refer to the",
    "returnPolicy_2": " After-sale Policy. ",
    "returnPolicy_3": "If you have any questions, please contact",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "No device information",
    "more": "More",
    "extendedWarrantyDeadline": "Warranty Expiration Date",
    "warrantyStatus": "Check Your (Extended Warranty) Status",
    "existWarranty": "Active",
    "nonExistentWarranty": "Non extended warranty",
    "userManual": "User Manual",
    "warrantyInfo": "Click 'Submit' to activate your extended warranty.",
    "newsletter": "Subscribe to our newsletter",
    "warrantySuccess": "Active",
    "download": "Download",
    "none": "None",
    "addNewDevice": "Add new device",
    "shopNow": "Shop Now"
  },
  "address": {
    "empty": "No address saved ~",
    "add": "Add a new address",
    "edit": "Edit the address",
    "userName": "Username",
    "address1": "Address 1",
    "address2": "Address 2",
    "country": "Country/Region",
    "country_code": "Country/ZIP Code",
    "province": "State/Province/Region",
    "province_code": "State/Province/ZIP Code",
    "city": "City",
    "zip": "Post Code",
    "phone": "Telephone Number",
    "company": "Company",
    "default": "Set to the default shipping address",
    "isDefault": "Default address",
    "error": "The delivery address is abnormal or the information is incomplete, please check"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "Success"
  },
  "user": {
    "log": {
      "expire": "Please login again"
    }
  },
  "message": {
    "order_status_shipping": "Shipped",
    "order_status_completed": "Completed",
    "order_status_processing": "Processing",
    "order_status_archived": "Shipped",
    "order_status_canceled": "Cancelled",
    "order_status_unpaid": "Unpaid",
    "device_not_found": "Device Not Found",
    "device_disable_can_not_set_warranty": "The current device is invalid, unable to set the extended warranty",
    "device_was_warranty": "The warranty of this device has been extended.",
    "device_not_support_warranty": "The current device does not support extended warranty"
  },
  "create": {
    "address": {
      "fail": "Failed to add address"
    }
  },
  "activation": {
    "email": {
      "expire": "Invalid Email Address"
    }
  },
  "select_country": {
    "title": "Select Your Country/Region"
  },


  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "Log in",
      "register": "Register",
      "already_a_member": "Already a member?",
      "points": "Points",
      "redeem": "Redeem",
      "rules": "Rules",
      "copy_successfully": "Copy Successfully",
      "outOfStock": "Out of Stock",
      "quick_login": "Login/Register"
    },
    // Banner 标题
    "banner": {
      "title": `Jackery<br/>Membership`,
      "description": `Earn Jackery Points, redeem exclusive benefits.<br/>Do something meaningful with Jackery.`,
      "points_balance": "Points Balance",
      "coming_soon": "Coming soon",
      "my_rewards": "My Rewards",
      "my_activity": "My Activity",
      "unused": "Unused",
      "to_be_redeemed": "To be Redeemed",
      "no_unused_rewards": "No available rewards",
      "no_convertible_rewards": "No convertible prizes",
      "no_activity_records": "No point activity records currently",
      "time": "Time",
      "action": "Action",
      "points": "Points",
    },
    "section_tabs": {
      "membership_benefits": "Membership Benefits",
      "redeem_points": "Redeem Points",
      "earn_points": "Earn Points",
      "guide": "Instructions",
      "faq": "FAQ",
    },
    // Benefits
    "membership_benefits": {
      "title": "Jackery Membership Exclusive Benefits",
      "banner_text": "Earn Jackery Points with your purchase & redeem exclusive rewards.",
      "coupon_benefit": "Get {num} off upon your first registration",
      "giftcard_benefit": "Free gift for your first order over {num} for once",
      "product_benefit": "Receive regular Product Care reminders",
      "gift_benefit": "Get 15% off once the warranty expires"
    },
    // Redeem Points
    "redeem_points": {
      "title": "Get Rewards by<br/>Redeeming your points",
      "unusedTips": "Use at checkout before it expires!",
      "pointTips": "You have {0} Jackery points.",
      "category_tabs": {
        "coupon": "Coupon",
        "gift_card": "Gift Card",
        "jackery_products": "Jackery Product",
        "virtualCard": "Extras",
      },
      "code": "Code",
      "redeem_confirm_title": "{0} points will be used. They are not refundable once processed.",
      "redeem_confirm_subtitle": "",
      "redeem_confirm_content": "Are you sure you want to redeem?",
      "successfully_redeemed": "Successfully redeemed!",
      "information_tips": "Information about the gift card has been sent to your mailbox, please check.",
      "copy_and_use": "Copy and use",
      "day": "DAY",
      "hour": "HRS",
      "minute": "MIN",
      "seconds": "SEC",
      "count_down_info": "The campaign has ended, and the rewards cannot be redeemed.Please refresh the page."

    },
    // 获取积分方式
    "earn_points": {
      "title": "Ways to Earn Points",
      "go_now": "GO NOW",
      "points_earned": "Points earned",
      "membership_registration": "Membership Registration",
      "membership_registration_desc": "Get {0} Jackery points when you join.",
      "email_subscribtion": "Email Subscribtion",
      "email_subscribtion_desc": "Get {0} Jackery points by subscribing to Jackery by Email.",
      "sms_subscribtion": "SMS Subscribtion",
      "sms_subscribtion_desc": "Get {0} Jackery points by subscribing to Jackery by SMS.",
      "fill_in_your_birthday": "Fill in Your Birthday",
      "fill_in_your_birthday_desc": "Get {0} Points by Filling in your birthday.<br/>Earn {1} points on each birthday and Get shopping points double.",
      "extend_your_warranty": "Extend Your Warranty",
      "extend_your_warranty_desc": `
        Get {0} Points by registering products on Jackery's official website per time.
        <br/>
        (For customers purchased from third-party channels.)
      `,
      "shop_and_earn": "Shop and Earn",
      "shop_and_earn_desc": "Go shopping,1 dollar spent = 1 point earned<br/>{0} points earned for the {1} orders<br/>{2} points for orders totaling over {3}",
      "tip": "Multi-site subscriptions only count as one subscription score."
    },
    "guide": {
      "title": "How Does It Work",
      "step_title_1": "1. Sign up",
      "step_content_1": "Create a Jackery account and enjoy exclusive membership benefits.",
      "step_title_2": "2. Earn Points",
      "step_content_2": "Complete tasks to earn points (such as filling out personal details, shopping products, joining events, etc.)",
      "step_title_3": "3. Get Rewards",
      "step_content_3": "Redeem exclusive offers (such as coupons, gift cards, etc) with your Jackery Points.",
      "learn_more": "Learn More",
    },
    // 注册 指引页
    "register_guide": {
      "title": "How to get your <br/>Jackery Membership Rewards?",
      "step_1_title": "Step 1 - Register and Login",
      "step_1_subtitle_1": "[Register]",
      "step_1_content_1": `① Go to the <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=GB" target="_blank">Registration Page</a> or choose "Create an account" on <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=GB" target="_blank">account.jackery.com</a>`,
      "step_1_content_2": "② Fill in your email address and password.",
      "step_1_content_3": "③ Confirm your new password and enter your verification code obtained via your email.",
      "step_1_content_4": `④ Tick "I agree to Jackery's privacy policy and terms of service" and "I agree to receive Jackery's latest news, deals, promotion and product information." Do read Jackery's Privacy Policy and Terms of Service to protect your rights.`,
      "step_1_content_5": `⑤ Click "Submit" to complete your registration.<br/>(If you have registered, please skip this step or login.)`,
      "step_1_subtitle_2": "[Login]",
      "step_1_content_6": `① 3 ways to login:`,
      "step_1_content_7": `Go to the Login Page`,
      "step_1_content_8": `Choose "Log In Now" on <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=GB" target="_blank">account.jackery.com</a>`,
      "step_1_content_9": `Click the "Account" icon on the top right of the Home Page.`,
      "step_1_content_10": `② Fill in your email address and password.`,
      "step_1_content_11": `③ Click "LOG IN" to enter your account center.`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/1_8fd0e4fd-407e-41f3-b161-d3cf4685ae5c.png?v=1690167490",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/2_ae43314d-83b1-4842-907c-4b95dc28fa9c.png?v=1690167490",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/3_9e0aba19-ec65-4488-be68-d870a9ca26d3.png?v=1690167490",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4_200a5f2b-cec0-42e1-aab1-c8ed3b2251c7.png?v=1690167490",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/5_1e064279-046f-4dd0-8298-e17248d87b4e.png?v=1690167490",
      "step_1_img_6_pc": "",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/1_b8ac433b-f13a-4476-887d-cbc0febc4dcc.png?v=1690167160",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/2_a288837a-883c-428b-8c28-975ad6a7bc76.png?v=1690167160",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/3.1.png?v=1690167160",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/3.2.png?v=1690167160",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/3.3.png?v=1690167160",
      "step_1_img_6_mb": "",

      "step_2_title": "Step 2 - Earn Jackery Points",
      "step_2_content_1": `Check "Ways to Earn Points" on <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB" target="_blank">Membership page</a> and learn how to earn Jackery points.`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/7_6d05e3e3-170c-4a7a-a126-7d64095a331f.png?v=1690167488",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/3.5.png?v=1690167160",

      "step_3_title": "Step 3 - Check Jackery Points",
      "step_3_content_1": `After logging in your account, you can check your points balance via <a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=GB" target="_blank">Account Center</a> or the <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB" target="_blank">Membership page</a>.`,
      "step_3_content_2": `① Account Center: There are three entries to check your points: "Overview", "My Profile", and "My Wallet".`,
      "step_3_content_3": `② By clicking on the ”Points Balance“, you can skip to the <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB" target="_blank">Membership page</a>, where you can also view your points balance.`,
      "step_3_content_4": `There are two types of points: Credited points and Pending points.`,
      "step_3_content_5": `The credited points will be displayed in the "Points Balance", and it can be used to redeem.`,
      "step_3_content_6": `The pending points will be shown in the "Coming Soon" section, and it cannot be used to redeem.`,
      "step_3_content_7": `However, the points in "Coming Soon" section will be transferred to the "Points Balance" on the specified date.`,
      "step_3_content_8": `For example, the shopping points earned from a purchase will be displayed in the "Coming Soon" section when the goods are shipped. 30 days later, those points will be moved into the "Points Balance".`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/8_64ae25c4-6afa-4ac2-94ca-98444e625215.png?v=1690167488",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/8_64ae25c4-6afa-4ac2-94ca-98444e625215.png?v=1690167488",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/9_6a8f0fdb-fb20-401b-a1db-8107b746106d.png?v=1690167488",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/10_8c441e72-4b43-4bb8-8f9e-7a182751504c.png?v=1690167489",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.1.png?v=1690167161",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.2.png?v=1690167161",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.3.png?v=1690167160",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.4.png?v=1690167162",

      "step_4_title": "Step 4 - Redeem Jackery Points",
      "step_4_content_1": `① Login the <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB" target="_blank">Membership page</a>, Click “My Rewards- To be Redeem” or browse the board of "Get Rewards by redeeming your points".`,
      "step_4_content_2": `The rewards that you can get will be highlighted, which can be redeemed with your current points balance.`,
      "step_4_content_3": `②Choose the reward that you want and click "Redeem", tap "Confirm" on the pop-up and then you can get the rewards.`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/11.png?v=1690167489",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/12_0b0c39cb-13ab-43c1-bee5-5a8a00c0fa8d.png?v=1690167488",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/13_d4ac610b-9600-4fb5-a206-a38b848bdca0.png?v=1690167488",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/14.png?v=1690167488",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.5.png?v=1690167162",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/4.6.png?v=1690167161",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/6_6e1be1ce-c434-46ef-b09f-57db7db93872.png?v=1690167161",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/7_e3c9df7e-4964-4d14-b8b7-b9f0d8a87d32.png?v=1690167161",

      "step_5_title": "Step 5 - Use the Rewards",
      "step_5_content_1": `If you redeemed Jackery Points for coupons or gift cards, click "Copy and Use", the code would have been copied. Shop on Jackery's official website, use the code at checkout, you can save more on your purchase.`,
      "step_5_content_2": `If you redeemed Jackery Points for the Jackery Product, you can get the product for free at checkout by pasting the discount code.`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/15_79651ce5-2555-425a-b7de-dcd57db96e13.png?v=1690167486",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/16_cb039a44-a5e0-4a1a-a610-bf561dd9664b.png?v=1690167488",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/8_99b4b060-331f-4527-b566-686fa6967c71.png?v=1690167160",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0569/8116/0124/files/9_37f03a23-4707-41bd-8fae-9f6811bb6b47.png?v=1690167160",
    },
    "faq": {
      "title": "FAQ",
      "membership": {
        "title": "Jackery Membership",
        "question_1": "1. What is Jackery Membership?",
        "answer_1": `Jackery Membership is a points-based program through which our customers (like you!) earn points on <a class="text-primary hover:text-primary" href="https://uk.jackery.com">uk.jackery.com</a>. Your points can be redeemed for rewards. It's totally free to join us. It's our way to say thank you and to show how much we appreciate your loyalty.`,
        "question_2": "2. How do I join Jackery Membership?",
        "answer_2": `
          You need to register a Jackery account to become one of Jackery members.
          <br/>
          If you do not have one, register now. Here is the <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">step-by-step guide</a> if you need to learn how.
          <br/>
          If you already have an account on Jackery official website, you can activate or login to your existing account.
        `,
        "question_3": "3. Do I need to re-register from Jackery's other site if I have already registered?",
        "answer_3": "Once the user has registered, their account is compatible with all official Jackery sites.",
        "question_4": "4. If I have many Jackery accounts, can I merge them into one?",
        "answer_4": "If you register with different email addresses, the Jackery ID will be different and can not be merged.",
        "question_5": "5. How do I cancel Jackery membership?",
        "answer_5": `
          Contact our customer service team to cancel your account. Points will be reset to zero.
          <br/>
          Logged out users can become Jackery members again through the registration process. Points will be recalculate.
          <br/>
          If you wish to re-register as a member, to ensure successful login, please use a different email account for registration.
        `,
        "question_6": "6. How do I get a free gift when I place an order over ￡1500?",
          "answer_6": `
          After you register, as long as you place the first order over ￡1500 you can get the gift, each member only once.`
      },
      "earn_points": {
        "title": "Earn Points",
        "setting1": "Users can redeem it up to {time} times in the past {num} days.",
        "setting2": "Users can only redeem {time} times.",
        "setting3": "Users can redeem benefits as long as they have sufficient points balance.",
        "question_1": "1. How to earn points?",
        "answer_1": `
          When you became a member of Jackery, you can earn points by completing specified tasks on the official website. Here are some notes for you.
          <br/>
          <br/>
          ① Membership Registration: If you registered before June 2023, you need to activate your membership by logging in and verifing your account. You can earn points for your first registration or activation.
          <br/>
          ② Email Subscription: You can earn points by subscribing to Email for once. No points for resubscribing after unsubscribing. Multi-site subscriptions only count as one subscription score.
          <br/>
          ③ SMS Subscription: You can earn points by subscribing to SMS for once. You need to enter your phone number in 'Account Center - My Profile' or on the page of 'Extend your warranty' , but not only subscribe from the footer of the website. Multi-site subscriptions only count as one subscription score.
          <br/>
          ④ Fill in your birthday: You can earn points by filling in your birthday. Get points on each birthday and Get shopping points double. Your birthday can only be modified once.
          <br/> 
          ⑤ Extend your warranty: Only for users who purchase from third-party platforms such as Amazon, Walmart, etc. Once you extend product warranty from the official website successfully, points will be issued according to the rules.
          <br/>
          ⑥ Shopping points: After purchasing products on Jackery official website, you can get points based on the total value of orders. In addition, the {0} order or the purchase value reaching {1} allow you to get extra points respectively. 
          <br/>
          ⑦ Joining Extra Points Activities: You can get points by participating in Extra Points Activities on Jackery official website. For details, please refer to the activity description on the page of Jackery membership.
        `,
        "question_2": "2. When will the points be credited to the account?",
        "answer_2": `
          There are two types of points arrival times:
          <br/>
          <br/>
          ① Fast issuance: After you complete the task specified on the official website, such as registration, subscription, filling in Birthday and warranty extention, the points will be issued to your account within 24 hours.
          <br/>
          ② Delayed issuance: Shopping related points will be issued as ‘coming soon points' after the goods are shipped. 30 days later, they will become active as 'points balance' (When an order includes multiple products, the date that the first product is dispatched shall prevail). The amount of points you receive for a qualifying order is based on its actual total payment.
          <br/>
          <br/>
          It should be noted that pending points cannot be used to redeem official website benefits.
        `,
        "question_3": "3. What to do if my points were not properly credited to my account?",
        "answer_3": `If you have questions related to your membership account and points balance, please contact our Customer Service at <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com.</a>`,
        "question_4": "4. Do points expire?",
        "answer_4": `
          Jackery points are valid for two years. Points earned by users will expire in 2 years from the official launch of the Membership Program（June 29th 2023), and expired points will be deducted from their account.
          <br/>
          In order to protect your rights and interests, please check and redeem your available points in time.
        `,
        "question_5": "5. Will I receive points for transactions made before the launch of the Jackery Membership Program?",
        "answer_5": "Yes! Jackery membership is officially launched on June 29th 2023, you will be issued original corresponding points for your shopping or completing specified tasks on Jackery official website (starting from Jan 1st, 2020), provided that you register as a Jackery's member. Original Points will expire in 2 years (starting from June 29th 2023).",
        "question_6": "6. Can tax or shipping charges be credited to points?",
        "answer_6": "No. Calculation of points excludes payment by the Member for shipping charges and all taxes, including without limitation, federal, state, and local taxes or use taxes.",
        "question_7": "7. What happens to my points if I cancel the order, return the product or apply for a partial refund of a product?",
        "answer_7": "Any order that is canceled,refund or returned will reverse or decrease the points you received from that purchase. Points would be deducted for partial refunds caused by price adjustments.",
        "question_8": "8. Special statement about Cross-site points / Exchange rate / Points display method",
        "answer_8": `
          Jackery Points can be earned and used on all of Jackery official websites.
          <br/>
          If you have point balances on Jackery's different official websites, the system will display the total point value after converting the balances at different sites through exchange rates.
          <br/>
          The total number of points will be converted based on the latest US dollar exchange rate on the 1st of each month, so the point balance will change according to the exchange rate every month.
          <br/>
          Points are in integer form, fractional parts will be counted in the point value, but will not be displayed and cannot be redeemed.
        `,
      },
      "redeem": {
        "title": "Redeem Points",
        "setting1": "Users can redeem it up to {time} times in the past {num} days.",
        "setting2": "Users can only redeem {time} times.",
        "setting3": "Users can redeem benefits as long as they have sufficient points balance.",
        "question_1": "1. How to redeem Jackery points for benefits?",
        "answer_1": `
          You can login at Member homepage (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB">membership.jackery.com</a>). The benefits that can be redeemed will be highlighted. You can select the benefits and click "Redeem".
          <br/>
          If you login to your account center, you can click the 'Points balance' to jump to the Member homepage to redeem your points for benefits.
          <br/>
          Points are not refundable once they have been redeemed. Please make sure you want to redeem before processing.
        `,
        "question_2": "2. What benefits can Jackery points be redeemed for?",
        "answer_2": `
        We provides you a wealth of benefits. Points can be redeemed for coupons, gift cards, Jackery product, interesting gift and more. The benefits are only valid to the site where it was collected or redeemed and cannot be used across sites. Please use the rewards within the validity period. Refer to the member homepage for details. Users can redeem rewards a limited number of times within a certain period; you will receive a system alert when the redemption limit has been reached. Once you enter a new cycle, you can redeem again.
          <br/>
          <br/>
          Notes
          <br/>
          ① Jackery coupon: Each coupon has a specific discount, minimum order amount, applicable products, and validity period. Please check the detailed information of each coupon when using it. Coupons cannot be combined or stacked with any other offers including sales, coupons, etc. {0}
          <br/>
          ② Jackery gift card: Each gift card has a specific denomination and validity period. Please check for more information of each gift card when using it. {1}
          <br/>
          ③Jackery Product：The offer cannot be combined or stacked with any other promotions, including sales and coupons. To claim the offer, enter the code and corresponding email address at checkout. Please remember that each Jackery Product coupon can only be used to purchase one specific item. {2}
          <br/>
          ④ Non-Jackery gift cards: There are a limited number of non-Jackery gift cards available. When the total inventory has been claimed, you may receive an "out of stock" message and will not be able to redeem your points. Non-Jackery gift cards will not display when they are out of stock. For details on the usage rules of non-Jackery gift cards, please refer to the corresponding brand’s official website. {3}
        `,
        "question_3": "3. How to check the benefits I redeemed?",
        "answer_3": `
          ① Login to the account center (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=GB">account.jackery.com</a>) , check it in 'My Wallet'. Or login to the member homepage (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=GB">membership.jackery.com</a>), check it in 'My Rewards'.
          <br/>
          ② You will receive emails for rewards collection notice, including coupons, gift cards, Jackery products, etc.
        `,
        "question_4": "4. Can my rewards, benefits, and points be converted into cash?",
        "answer_4": `
          Rewards, benefits, and points earned through Jackery Membership Program have no cash value, cannot be converted into cash and cannot be transferred to someone else (Except for the gift card which you can send to your friends or family members.).
          <br/>
          It is not allowed to sell the benefits you redeemed to a third party. 
          <br/>
          Jackery has the right to take legal action against unauthorized transactions.
        `,
        "question_5": "5. Do I need to pay shipping fees for redeemed products?",
        "answer_5": "In most cases, we offer free shipping for redeemed products. However, you may need to pay shipping fees for specific products.",
        "question_6": "6. Can I cancel, return or exchange rewards redeemed with points?",
        "answer_6": `
          Once the redemption is completed, it cannot be canceled. The rewards cannot be returned or exchanged.
          <br/>
          If the product arrives damaged, please contact customer service at <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=GB" target="_blank">support.jackery.com</a> within 7 days.
        `,
        "question_7": "7. Can the redeemed products be shipped with products I purchased from Jackery?",
        "answer_7": `
          No. Redeemed products (an item obtained through point redemption or from the draw) will be shipped separately and will be delivered within 60 working days.
          <br/>
          You need to submit or confirm the address information so that the official website can arrange delivery in time.
        `,
      },
      "others": {
        "title": "Others",
        "question_1": "1. Contact us for more help",
        "answer_1": `
          If you have more questions, please email our Customer Service at <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>, visit <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=GB" target="_blank">support.jackery.com</a> for help, or click to contact us.
        `,
        "question_2": "2. Handling of violations of Jackery membership points rules",
        "answer_2": `If a user has acted maliciously or falsely in obtaining points, using points or gaining benefits, Jackery reserves the right to freeze or cancel the user's points, stop providing services or cancel the user's account as appropriate, and not accept the user's application for re-registration.`,
        "question_3": "3. Special statement",
        "answer_3": `
          All rights about Jackery Membership reserved by Jackery.
          <br/>
          Membership benefits may change periodically; please refer to the membership homepage for details.
        `,
        "question_4": "4. Malicious registration (including use of false information, or creating multiple accounts to obtain additional rewards) will result in disqualification",
        "answer_4": `
          Jackery reserves all rights to cancel rewards or orders, obtained via the following methods:
          <br/>
          ① False Information Usage: It is strictly prohibited to use false names, addresses, email addresses, or other personal information to create accounts.
          <br/>
          ② Multiple Account Creation: It is strictly prohibited for a single user to create multiple accounts, to obtain additional rewards or discounts. This includes using different email addresses, or any identity information to register multiple accounts.
          <br/>
          ③ Automated Registration Tools: Use of any automated tools or scripts, for bulk account registration is strictly prohibited.
          <br/>
          ④ Malicious Behaviour: Any other behaviour deemed to be malicious, including exploiting system vulnerabilities, or engaging in fraudulent transactions, is strictly prohibited.
        `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'points',
    "pointsBalance": "Points Balance",
    "comingSoon": "Coming Soon",
    "benefitsForYou": "Benefits for you",
    "welcomeGift": "Welcome Gift",
    "welcomeGiftInfo": "100% winning, Coupons/ Gift cards / Actual items are waiting for you!",
    "firstRegistration": "First Registration",
    "firstRegistrationInfo": "Get {num} off upon your first registration for Jackery's membership.",
    "largeOrderBenefits": "Large Order Benefits",
    "largeOrderBenefitsInfo": "Free gift for your order over {num} for once.",
    "referral": "Refer a Friend",
    "referralInfo": "Give discount to your friends, get rewards when they subscribe and buy!",
  },
  // 我的钱包
  "wallet": {
    "unit": 'points',
    "jackeryPointsBalance": "Points Balance",
    "comingSoon": "Coming Soon",
    "pointsDetails": "My Activity",
    "redeemMyCoins": "Redeem My Points",
    "coupon": "Coupon",
    "giftCard": "Gift Card",
    "jackeryProducts": "Jackery Product",
    "extras": "Extras",
    "used": "Used",
    "expired": "Expired",
    "daysLeft": "{num} days left(UnUsed)",
    "partially_used": "Partially Used",
    "code": "Code",
    "rules": "Rules",
    "copyAndUse": "Copy and Use",
    "noCoupon": "No coupons available",
    "noGiftCard": "No gift card available",
    "noJackeryProducts": "No Jackery product coupon available",
    "noExtras": "No extras available",
    "redeemMyPoints": "Redeem My Points",
    "viewMore": "View More",
    "couponRules": {
      "one": {
        "title": "1. What is a coupon ?",
        "step1": "1) Coupons are customer feedback methods that Jackery distributes to users through promotional activities, targeted marketing, member point redemption, and other forms to reduce purchase payments.",
        "step2": "2) The coupons launched by Jackery exist in electronic form and can be used to purchase products on Jackery's official website.",
        "step3": "3) When purchasing and paying for an order, enter the discount code of the Jackery coupon, and the card discount/amount will be automatically deducted from the total order amount. The coupon cannot be converted into cash.",
      },
      "two": {
        "title": "2. How to check coupons?",
        "step1": "1) Register as a Jackery member and check coupons (including unused, used, and expired information) in the Account Center - My Wallet - Coupons.",
        "step2": "2) Please refer to the validity period of the coupon. Please use it within the validity period, and no refund will be made if it is overdue.",
      },
      "three": {
        "title": "3. How to use the coupon:",
        "step1": "1) Each coupon has a specific discount, minimum order amount, applicable products, and validity period. Please check the detailed information of each coupon when using it.",
        "step2": "2) Applicable sites: The coupon is valid to the site where it was collected or redeemed and cannot be used across sites.",
        "step3": "3) Only one coupon can be used for the same order and cannot be combined with promotions; If an order includes multiple items, the coupon applies only to qualifying items.",
        "step4": "4) Some coupons are bound to an account and are only for personal account use. The email address on the order page must be consistent with the email address of the coupon recipient and cannot be transferred; Some coupons can be gifted to other users for use, please refer to the coupon usage instructions for details.",
        "step5": "5) The coupon can be used together with gift cards.",
        "step6": "6) Members can redeem points for coupons, and after deducting points, both points and coupons are irrevocable. There may be a limit on the number of times member points can be redeemed for coupons within a certain period of time .",
        "step7": "7) If the order uses a coupon, the coupon cannot be returned in the event of a refund."
      },
      "four": {
        "title": "4. Handling of Violation of Coupon Usage Rules",
        "step1": "If you use a coupon that is forged, bought, sold, or transferred from another individual, Jackery has the right to freeze or invalidate the coupons, cancel orders that used coupons, cancel the user's coupon qualification, stop providing services, or cancel the user's account, and has the right not to accept the user's re-registration application."
      }
    },
    "giftRules": {
      "one": {
        "title": "1. What is a Jackery gift card ?",
        "step1": "1) Gift cards are customer feedback methods that Jackery distributes to users through promotional activities, targeted marketing, member point redemption, and other forms to reduce purchase payments.",
        "step2": "2) The gift cards launched by Jackery exist in electronic form and can be used to purchase products on Jackery's official website.",
        "step3": "3) When shopping for payment orders, enter the Jackery gift card code, and the card amount will be automatically deducted from the total order amount. Gift cards cannot be converted into cash.",
      },
      "two": {
        "title": "2. How to check gift cards?",
        "step1": "1) Register as a Jackery member and check gift cards (including unused, used, and expired information) in the Account Center - My Wallet - Gift Cards.",
        "step2": "2) Please refer to the validity period of the gift card. Please use it within the validity period and Jakcery do not make up for any overdue payments.",
      },
      "three": {
        "title": "3. Instructions for using gift cards:",
        "step1": "1) Each gift card has a specific denomination and validity period. Please check for more information of each gift card when using it.",
        "step2": "2)  Applicable sites: The gift card is valid to the site where it was collected or redeemed and cannot be used across sites.",
        "step3": "3) There is no minimum order amount required when using a gift card. It applies to all products.",
        "step4": "4) Gift cards are not limited to personal account usage and can be transferred as gifts to friends.",
        "step5": "5) Multiple gift cards can be used for the same order, can be combined with promotions, and can be used together with coupons.",
        "step6": "6) Members can redeem points for gift cards, and after deducting points, both points and gift cards are irrevocable.",
        "step7": "7) There may be a limit to the number of times you can redeem your membership points for gift cards within a certain period of time, and the system will remind you when the number of times is used up.",
        "step8": "8) If the order uses a gift card, the gift card cannot be returned in the event of a refund.",
      },
      "four": {
        "title": "4. Handling of Violation of Gift Card Usage Rules",
        "step1": "If you use a gift card that is forged, bought or sold, Jackery has the right to freeze or invalidate the gift card, cancel orders that used gift cards, cancel the user's gift card qualification, stop providing services, or cancel the user's account, and has the right not to accept the user's re-registration application."
      }
    },
    "productRules": {
      "one": {
        "title": "1. What is a Jackery Product coupon ?",
        "step1": "1) Jackery Product giveaways are a type of coupon that Jackery distributes to users through promotional activities, targeted marketing, member point redemption, and other forms as feedback to customers.",
        "step2": "2) The Jackery Product launched by Jackery exists in electronic form as coupons, and can be purchased through full or partial credit reduction for corresponding products on Jackery's official website.",
        "step3": "3) When purchasing and paying for an order, enter the Jackery product's discount code, and the card discount/amount will be automatically deducted from the total order amount. Coupons cannot be converted into cash.",
      },
      "two": {
        "title": "2. How to check the Jackery Product coupon?",
        "step1": "1) Register as a Jackery member and check Jackery Product coupons (including unused, used, and expired information) in the Account Center - My Wallet - Jackery Product.",
        "step2": "2) Please refer to the validity period of the Jackery Product coupon. Please use it within the validity period and Jackery do not make up for any overdue payments.",
      },
      "three": {
        "title": "3. Instructions for using Jackery Product coupons:",
        "step1": "1) Each Jackery Product coupon has a specific validity period, it is necessary to check the detailed information when using it.",
        "step2": "2) Applicable sites: Jackery Product coupons claimed or redeemed on this site are only for use on this site and cannot be used across sites.",
        "step3": "3) Only one coupon can be used for the same order and cannot be combined with promotions; If multiple products appear on the same order, the coupon is only valid for the corresponding product and is not valid for other products.",
        "step4": "4) The Jackery Product coupon is bound to an account and is only for personal account use. The email address on the checkout page must be consistent with the email address of the coupon recipient and cannot be transferred.",
        "step5": "5) Members can use points to redeem Jackery Product coupons, and after point deductions, both points and coupons are irrevocable. There may be a limit on the number of times member points can be redeemed for coupons within a certain period of time.",
        "step6": "6) If the order uses a coupon, the coupon cannot be returned in the event of a refund."
      },
      "four": {
        "title": "4. Jackery Product Gift Exchange Terms",
        "step1": "1) Log in to Jackery's official website and go to the corresponding product details page, click to purchase.",
        "step2": "2) Enter the corresponding discount code on the checkout page (Paste the code {XXX} at checkout).",
        "step3": "3) Please note that the email on the checkout page is the email for receiving coupons."
      },
      "five": {
        "title": "5. Handling of Violation of Coupon Usage Rules",
        "step1": "If you use a coupon that is forged, bought, sold, or transferred from another individual, Jackery has the right to freeze or invalidate the coupons, cancel orders that used coupons, cancel the user's coupon qualification, stop providing services, or cancel the user's account, and has the right not to accept the user's re-registration application."
      }
    },
    "extraRules": {
      "one": {
        "title": "1. What is Jackery Extras?",
        "step1": "1) Jackery Extras  are gifts from non Jackery brands, including vouchers, coupons, gift cards, and physical gifts.",
        "step2": "2) Jackery distributes to users through promotional activities, targeted marketing, member point redemption, and other forms as feedback to customers. After redeeming points for Jackery Extras, points will be deducted, and both points and gifts cannot be withdrawn.",
      },
      "two": {
        "title": "2. How to check Jackery Extras?",
        "step1": "1) Register as a Jackery member and view it in the Account Center - My Wallet - Extras.",
        "step2": "2) Redeem your offers within the validity period. It can not be reissued once it has expired.",
        "step3": "3) The offer details will be sent via email. The claimant can view the relevant info from their mailbox."
      },
      "three": {
        "title": "3. Claim Jackery Extra Gift",
        "step1": {
          "title": "1) Rules when claming a physical gift:",
          "tip1": "① Non Jackery physical gift need to be filled in the address clearly.",
          "tip2": "② Your item will be sent out in 30-90 days after claiming it.",
          "tip3": "③ The shipping details including tracking number will be sent via email once the item is shipped. ",
          "tip4": "④ If the physical prizes received are damaged or have other issues, please feel free to contact our customer service.",
        },
        "step2": {
          "title": "2) Non Jackry brand virtual gift card considerations",
          "tip1": "① Non Jackry brand virtual gift cards details, including 'what & how' will be sent via email.",
          "tip2": "② The deadline for receiving virtual gift cards from non Jacekry brands is XX years, which is activated upon receipt. After activation, the validity period of the gift card will be determined based on the validity period specified by the corresponding brand. Please refer to the corresponding brand website for details",
          "tip3": "③ The specific usage rules for virtual gift cards of non Jacekry brands can be found in the introduction of gift card redemption platform rules.",
        }
      },
      "four": "4. In the event of a dispute, Jackery reserves the right of final decisions."
    }
  },
  // My Service
  "service": {
    'imgText1': 'Welcome to Jackery',
    'imgText2': 'Support',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': 'Go to Jackery Support for Smart Service.',
    'contactUs': 'Contact Us',
    'viewSupport': 'Go to Support',
  },
  // My Jackery Life
  "jackeryLife": {
    'title': 'My Sharing',
    'imgText1': '',
    'imgText2': '',
    'pcImage': 'https://cdn.shopify.com/s/files/1/0569/8116/0124/files/PC-EN_500x280_d3fdae3a-9a5b-46d0-a665-f3d27c1be049.png?v=1721620437',
    'mbImage': 'https://cdn.shopify.com/s/files/1/0569/8116/0124/files/PC-EN_500x280_d3fdae3a-9a5b-46d0-a665-f3d27c1be049.png?v=1721620437',
    'text': 'Post any photo or video featuring Jackery products with your user experience for a chance to win a £100 Gift Card. ',
    'learnMore': 'Learn More',
  },
  "wishlist": {
    "empty": "No Products in Wishlist",
    "shopNow": "SHOP NOW",
    "modalTitle": "Remove the product, and view later? ",
    "modalCancel": "CANCEL",
    "modalOk": "CONFIRM",
    "removeToast": "The product has been removed from your Wishlist.",
    "createdDesc": "Date Added Newest",
    "createdAsc": "Date Added Oldest",
    "priceDesc": "Price High to Low",
    "priceAsc": "Price Low to High",
    "purchased": "PURCHASED",
    "buyNow": "BUY NOW",
    "selectAll": "Select All",
    "selected": "Selected",
    "amount": "Total Amount",
    "addOn": "Added on",
    "notGoodsGotoBuy": 'Please select an item.',
    "viewMoreHref": "https://uk.jackery.com/collections/all"
  },
  // Trade-in
  "trade-in": {
    "title": "My Upgrade",
    "orderNumber": "Order Number",
    "productModel": "Product Model",
    "acutalProductModel": "Acutal Product Model",
    "trackingNo": "Tracking Number",
    "level": "Acutal Product Level",
    "estimatedPrice": "Estimated Price",
    "transactionPrice": "Transaction Price",
    "cardCode": "Gift Card Code",
    "priceConfirmation": "Price Confirmation",
    "startDate": "Start Date",
    "endDate": "End Date",
    "detailPage": "Detail",
    "productInformation": "Detail Information",
    "timeLine": "Upgrade Progress",
    "date": "Date",
    "acceptText": "Thank you for your confirmation. You can now use your gift card.",
    "acceptNote": "*Please note that the gift card is valid for only 10 days.",
    "rejectText": "Thank you for your confirmation. Since you do not accept the product quote, we are unable to provide a gift card.",
    "rejectNote": `Contact <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>  for product return (*please note that the return shipping cost will be your responsibility)`,
    "closed": "Closed",
    "empty": "No information.",
    "sendShippingLabel": "Send Shipping Label",
    "packageInTransit": "Package in Transit",
    "packageDelivered": "Package Delivered",
    "inspectionAndVerification": "Inspection and Verification",
    "QuoteConfirmation": "Quote Confirmation",
    "issuingGiftCard": "Issuing Gift Card",
    "productReturn": "Product Return",
    "orderCompleted": "Order Completed",
    "orderCancelled": "Order Cancelled",
    "viewMore": "View More",
    "awaitingFeedback": "Awaiting Feedback",
    "orderStatus": "Order Status",
    "copy": "Copy"
  },
  "old-invite-new": {
    "myRewardsTitle": "My Rewards",
    "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
    "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
    "viewMore": "View More",
    "myReferralsTitle": "My Referrals",
    "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
    "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
    "claim": "Claim",
    "claimed": "Claimed",
    "expired": "Expired",

    // 补充
    "myReferral": "My Referral",
    "enterEmail": "Enter Your Email Address",
    "invalidEmail": "Invalid Email Address",
    "messageSent": "Message Sent",
    "copy": "COPY",
    "copied": "COPIED",
    "copiedSuccess": "COPIED SUCCESS!",
    "friendEmail": "Enter Your Friend's Email",
    "referee": "Referee",
    "referralType": "Referral Type",
    "rewards": "Rewards",
    "status": "Status",
    "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
  }
}
